* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
::placeholder {
  color: black;
  opacity: 92%;
}
.contact {
  width: 100%;
  overflow-x: hidden;
  background-color: #ffffff;
  color: black;
}
.contact-container {
  display: flex;
  width: 90%;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 3rem;
  gap: 4%;
  justify-content: space-around;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.contact-left {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.left-up {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5rem;
}
.left-down {
  margin-top: 2rem;
}
.form {
  height: 2.5rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: aliceblue;
  border: none;
}
.message {
  border: none;
  grid-column: 1/3;
  height: 8rem;
  border-radius: 4px;
  background-color: aliceblue;
}
.small-heading {
  color: #e8bb36;
}
.contact-right {
  display: flex;
  flex-direction: column;
  width: 48%;
  justify-content: space-between;
}
.contact-right h2 {
  font-size: 35px;
}
.icons {
  width: 2.5rem;
  grid-row: 1/3;
  align-items: center;
  display: flex;
}
.icon-grp {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.right-down {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.para {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.button-grp {
  /* border: 2px solid #ffd43b; */
  /* width: 13rem; */
  display: flex;
}
.button {
  display: flex;
  color: black;
  padding-left: 0.8rem;
  background-color: white;
  border: none;
  align-items: center;
  font-size: 10px;
  width: 100%;
}
.arrow {
  background-color: #e8bb36;
  object-fit: contain;
  margin-left: 5rem;
  padding-left: 0.8rem;
}
input:focus {
  border: 3px solid #e8bb36;
  outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 950px) {
  /* .small-heading {
    display: none;
  } */
  br {
    display: none;
  }
  .contact-container {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 3rem;
    justify-content: space-around;
  }
  .contact-left {
    width: 100%;
    margin-bottom: 3rem;
  }
  .contact-right {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 2rem;
  }
  .contact-right h2 {
    margin: 0 auto;
  }
  .button-grp {
    margin: 0 auto;
  }
}
@media (max-width: 550px) {
  .left-up {
    display: flex;
    flex-direction: column;
  }
  .button-grp {
    margin: 0 auto;
  }
  .right-down {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;
  }
}
