.footerContainer {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #222b5a;
}

.bottom {
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
}
.top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
  margin-left: 1rem;
  margin-right: 1rem;
}

.left {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 3.5rem;
}
.head {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}
.head > p{
  font-size: small;
}
/* .content {
  margin-left: 4rem;
  margin-right: 4rem;
} */
.footerContainer h3 {
  font-size: 1.5rem;
}
.logoContainer,
.logoContainer > img {
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 0.1rem;
  color: yellow;
}
.icons {
  display: flex;
  height: 2rem;
  margin-top: 1rem;
}

.icons > svg {
  display: flex;
  height: 2rem;
  margin-right: 1.5rem;
  color: white;
}
.icons > svg:hover {
  color: #e8bb36;
  cursor: pointer;
}
.companyList li:hover {
  cursor: pointer;
  color: #e8bb36;
}
.companyList li::marker {
  color: #e8bb36;
}
.right {
  width: 60%;
  display: flex;
  margin-left: 3.5rem;
  margin-top: 3.5rem;
  justify-content: space-evenly;
}
@media (max-width: 1050px) {
  .footerContainer h3 {
    font-size: 1rem;
    font-weight: bold;
  }
  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .left {
    align-items: center;
    width: 100%;
    margin: 2rem;
  }
  .head {
    margin-left: -3rem;
  }
  .right {
    width: 100%;
    display: flex;
    align-items: space-between;
    margin: 0px;
    margin: 1rem 0rem;
  }
}

@media (max-width: 500px) {
  .left {
    margin: 0px;
  }
  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    margin: 0px;
    margin: 1rem 0rem;
  }
  .right > div {
    margin-bottom: 1rem;
  }
}
