/* Global Variables */
:root {
    --color01: #ffffff;
    --color02: #1f2027;
    --color02Hover: #1f2027;
    --color03: #2a2a33;
    --color04: #1f2027;
    --color04Hover: #1f2027;
    --color05: #ffd400;
    --color06: #f96e6e;
    --color06Hover: #f62929;
    --color07: #0097a7;
    --color08: #f4f4f4;
    --color09: #fbfbfb;
    --color10: #ffd400;
    --color11: #f96e6f;
    --color12: #362e42;
    --color13: #aaa;
}

.container {
    width: 100%;

    /* Flex */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 300px));
    gap: 25px;
    justify-content: center;
    align-items: center;
}
@media (max-width: 644px) {
    .container {
        gap: 40px;
    }
}

.card {
    width: 300px;
    min-height: 470px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;

    /* Flex */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transform: scale(1.04);
}

.card::before {
    background-color: var(--color05);
    color: var(--color02);
    padding: 20px 35px 10px;

    /* Transform */
    transform: translate(calc(31px + 35px), -35px) rotate(45deg);
    transform-origin: top left;
    
    content: 'NEW';
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.card:nth-of-type(2)::before {
    background-color: var(--color06);
    color: var(--color03);
}

.card:nth-of-type(3)::before {
    background-color: var(--color07);
    color: var(--color12);
}

/* Info */
.card__info {
    width: 100%;
    height: 130px;

    /* Background */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    /* Position */
    position: relative;
    z-index: 0;

    /* Flex */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card__info::before {
    background-color: var(--color02);
    width: 100%;
    height: 100%;
    opacity: 0.8;

    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
}

.card:nth-of-type(2) .card__info::before {
    background-color: var(--color03);
}

.card:nth-of-type(3) .card__info::before {
    background-color: var(--color04);
}

.card__price {
    color: var(--color05);
    font-size: 22px;
    font-weight: 400;
}

.card__priceSpan {
    color: var(--color13);
}

.card:nth-of-type(3) .card__priceSpan {
    color: var(--color01);
}

/* Content */
.card__content {
    background-color: var(--color09);
    width: 100%;
    height: calc(100% - 130px);
    border-top: solid 2px;
    border-bottom: solid 2px;

    /* Flex */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card__rows {
    width: 100%;
}

.card__row {
    color: var(--color13);
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

.card__row:nth-of-type(odd) {
    background-color: var(--color08);
}

.card__link {
    color: var(--color01);
    padding: 10px 15px;
    margin: 20px 0;
    text-decoration: none;
    border-radius: 50px;
}

.card__link:hover {
    background-color: var(--color02Hover) !important;
}

.card:nth-of-type(2) .card__link:hover {
    background-color: var(--color06Hover) !important;
}

.card:nth-of-type(3) .card__link:hover {
    background-color: var(--color04Hover) !important;
}